.ui.container {
  padding-top: 3em;;
}

.link {
  position: absolute;
  top: -50px;
}

.cover {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
}

.modal {
  left: 40%;
  top: 30%;
  padding: 10px;
}

.icon.buttons {
  position: absolute;
  right: 0px;
}

.buttons {
  display: flex;
  justify-content: space-around;
}

.three .field {
  width: 33%;
}

.four .field {
  width: 25%;
}

.ui.message {
  position: absolute;
  top: -70px;
  right: 0;
  padding-right: 2.5em;
}

.ui.message.register {
  position: absolute;
  top: 0;
  right: 50px;
  padding-right: 2.5em;
}

.ui.container {
  padding-top: 5em;
}